import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { Store } from '@ngrx/store';
import { configure, defaultDispatchTagPreprocessor } from 'g2i-ngrx-utils';
import { ResolverMonitorService } from 'shared/services/resolver-monitor';

import { environment } from '../environments/environment';
import { AppState } from '../shared/store/interfaces';


@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet>',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  styles: [` :host { flex: 1; display: flex; } `],
  imports: [ RouterModule ],
})
export class AppComponent {

  constructor(
    readonly store: Store<AppState>,
    readonly resolverMonitor: ResolverMonitorService, // please do not remove this constructor arg.
    readonly router: Router,
  ) {
    this.ensureCanDeactivateGuardWorksCorrectlyOnBackPress();
    this.configureNgrxUtils();
    this.configureUsersnap();
    this.configureGoogleTagManager();
  }

  private ensureCanDeactivateGuardWorksCorrectlyOnBackPress() {
    // ref: https://github.com/angular/angular/issues/13586#issuecomment-872592435
    this.router.canceledNavigationResolution = 'computed';
  }

  private configureNgrxUtils() {
    configure({
      dispatchFunction: (arg: any) => this.store.dispatch(arg),
      dispatchTagPosition: 'append',
      dispatchTagPreprocessor: defaultDispatchTagPreprocessor,
    });
  }

  private configureUsersnap() {
    const userSnapScript = document.createElement('script');
    userSnapScript.defer = true;
    userSnapScript.src = 'https://widget.usersnap.com/global/load/15127bfc-d412-4714-9c14-61776ed3db6e?onload=onUsersnapCXLoad';
    document.getElementsByTagName('head')[0].appendChild(userSnapScript);
    userSnapScript.onload = () => {
      (window as any).onUsersnapCXLoad = (api: any) => {
        api.init();
      };
    };
  }

  private configureGoogleTagManager() {
    if (environment.production) {
      const script = document.createElement('script');
      script.setAttribute('async', 'true');
      script.src = 'https://www.googletagmanager.com/gtag/js?id=G-91D1YMHHQ3';
      document.body.appendChild(script);
      script.onload = () => (window as any).dataLayer.push('js', new Date(), 'config', 'G-91D1YMHHQ3');
      ((w: any, d: any, s: string, l: string, i: string) => {
        w[l] = w[l] || []; w[l].push({
          // eslint-disable-next-line @typescript-eslint/naming-convention
          'gtm.start': new Date().getTime(),
          event: 'gtm.js'
        }); const f = d.getElementsByTagName(s)[0];
        const j = d.createElement(s);
        const dl = l !== 'dataLayer' ? '&l=' + l : '';
        j.async = true;
        j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
        f.parentNode.insertBefore(j, f);
      })(window, document, 'script', 'dataLayer', 'GTM-TDF8SKM');
    }
  }

}
