import { manageStateSlice } from 'g2i-ngrx-utils';
import {
  Job,
  JobApplication,
  StepExemptionRequest,
  StepMobileStepOff,
  StepMobileStepOn,
  StepServiceEnd,
  StepServiceStart,
} from 'shared/api/schedule/api-schedule.responses';
import { DayVehicleSession, SaveJobRequest } from 'shared/api/schedule/job/api-job.requests';
import { JobId, JobSlotId, ServiceBlockId, ProfessionalTypeId, VehicleBookingSlotId } from 'shared/constants/id.constants';
import { isDefined } from 'shared/utils/strict-null-check.utils';

export const scheduleProfessionalsState = manageStateSlice({
  jobs: new Array<Job>(),
  jobSlotIdsEnabledAfterAcknowledgement: new Array<JobSlotId>(),
  jobDialog: {
    jobToSaveBeforeUpdates: null as any as SaveJobRequest,
    jobToSave: null as any as SaveJobRequest,
    serviceBlockIdsThatOverlapWithOtherJobs: new Array<string>(),
    editingStepIndex: 0,
    editingStep3Index: 0,
    step1Dirty: false,
    step2Dirty: false,
    step3Dirty: false,
    step1Error: '',
    step2Error: '',
    step3Error: '',
  }
})({
  jobSlotStepServiceStartOrEnd: (state, step: (StepServiceStart | StepServiceEnd | StepMobileStepOn | StepMobileStepOff)
    & ({ whenStepExemptionRequest: StepExemptionRequest } | { whereStepExemptionRequest: StepExemptionRequest })) => {
    state.jobs.forEach(job => {
      job.jobSlots.forEach(slot => {
        if (slot.stepServiceStart.id === step.id) {
          slot.stepServiceStart = step as StepServiceStart;
        } else if (slot.stepServiceEnd.id === step.id) {
          slot.stepServiceEnd = step as StepServiceEnd;
        } else if (slot.stepMobileStepOnId === step.id) {
          job.stepMobileStepOns.replace(step as StepMobileStepOn, e => e.id);
        } else if (slot.stepMobileStepOffId === step.id) {
          job.stepMobileStepOffs.replace(step as StepMobileStepOff, e => e.id);
        }
      });
    });
  },
  jobs: (state, payload: Job[]) => {
    state.jobs = payload;
  },
  jobAdd: (state, payload: Job) => {
    state.jobs.push(payload);
  },
  jobUpdate: (state, payload: Job) => {
    state.jobs[state.jobs.findIndex(j => j.id === payload.id)] = payload;
  },
  jobApplication: (state, payload: JobApplication) => {
    state.jobs[state.jobs.findIndex(j => j.id === payload.jobId)].jobApplication = payload;
  },
  jobSave: (state, payload: JobId) => {
    const jobSlotIds = state.jobs.findOrThrow(j => j.id === payload).jobSlots.map(js => js.id);
    state.jobSlotIdsEnabledAfterAcknowledgement
      = state.jobSlotIdsEnabledAfterAcknowledgement.filter(id => !jobSlotIds.includes(id));
  },
  jobConflicts: (state, payload: { jobId: JobId; conflicts: string[] }) => {
    state.jobs[state.jobs.findIndex(j => j.id === payload.jobId)].conflicts = payload.conflicts;
  },
  jobSlotIdEnabledAfterAcknowledgement: (state, payload: JobSlotId) => {
    state.jobSlotIdsEnabledAfterAcknowledgement.push(payload);
  },
  jobSlotIdDisabledAfterAcknowledgement: (state, payload: JobSlotId) => {
    state.jobSlotIdsEnabledAfterAcknowledgement.remove(e => e === payload);
  },
  jobStep1Error: (state, payload: string) => {
    state.jobDialog.step1Error = payload;
  },
  jobStep2Error: (state, payload: string) => {
    state.jobDialog.step2Error = payload;
  },
  jobStep3Error: (state, payload: string) => {
    state.jobDialog.step3Error = payload;
  },
  jobStep1Dirty: (state, payload: boolean) => {
    state.jobDialog.step1Dirty = payload;
  },
  jobStep2Dirty: (state, payload: boolean) => {
    state.jobDialog.step2Dirty = payload;
  },
  jobStep3Dirty: (state, payload: boolean) => {
    state.jobDialog.step3Dirty = payload;
  },
  jobToSaveProfessionalTypeId: (state, payload: ProfessionalTypeId) => {
    isDefined(state.jobDialog.jobToSave).professionalTypeId = payload;
  },
  editingStepIndex: (state, payload: number) => {
    state.jobDialog.editingStepIndex = payload;
  },
  editingStep3Index: (state, payload: number) => {
    state.jobDialog.editingStep3Index = payload;
  },
  jobToSave: (state, payload?: SaveJobRequest) => {
    state.jobDialog.jobToSave = payload || null as any;
  },
  jobToSaveBeforeUpdates: (state, payload: SaveJobRequest) => {
    state.jobDialog.jobToSaveBeforeUpdates = payload;
  },
  serviceBlockIdsThatOverlapWithOtherJobs: (state, payload: ServiceBlockId[]) => {
    state.jobDialog.serviceBlockIdsThatOverlapWithOtherJobs = payload;
  },
  jobToSaveSessionRemove: (state, payload: DayVehicleSession) => {
    const toSave = isDefined(state.jobDialog.jobToSave);
    toSave.dayVehicleSessions = toSave.dayVehicleSessions
      .filter(s => s.day !== payload.day || s.vehicleId !== payload.vehicleId);
  },
  jobToSaveSessionAdd: (state, payload: DayVehicleSession) => {
    state.jobDialog.jobToSave.dayVehicleSessions.push(payload);
  },
  jobToSaveSession: (state, payload: DayVehicleSession) => {
    const index = state.jobDialog.jobToSave.dayVehicleSessions.findIndex(s => s.id === payload.id);
    state.jobDialog.jobToSave.dayVehicleSessions[index] = payload;
  },
  jobToSaveSessionSlotIdRemove: (state, payload: { session: DayVehicleSession; vehicleBookingSlotId: VehicleBookingSlotId }) => {
    const sessionIndex = state.jobDialog.jobToSave.dayVehicleSessions
      .findIndex(s => s.day === payload.session.day && s.vehicleId === payload.session.vehicleId);
    state.jobDialog.jobToSave.dayVehicleSessions[sessionIndex].vehicleBookingSlotIds
      = state.jobDialog.jobToSave.dayVehicleSessions[sessionIndex].vehicleBookingSlotIds.filter(id => id !== payload.vehicleBookingSlotId);
  },
  jobToSaveSessionSlotIdAdd: (state, payload: { session: DayVehicleSession; vehicleBookingSlotId: VehicleBookingSlotId }) => {
    const sessionIndex = state.jobDialog.jobToSave.dayVehicleSessions
      .findIndex(s => s.day === payload.session.day && s.vehicleId === payload.session.vehicleId);
    state.jobDialog.jobToSave.dayVehicleSessions[sessionIndex].vehicleBookingSlotIds.push(payload.vehicleBookingSlotId);
  },
});
