import { manageStateSlice } from 'g2i-ngrx-utils';
import { TrackerPosition } from 'shared/api/asset/api-asset.response';
import { CreateNote, ListNotes } from 'shared/api/note/api-note.responses';
import {
  DayScheduleSummaries,
  ExemptionReview,
  MediaReview,
  ProjectGrant,
  ProjectGrantsSummary,
  ProjectSummary,
  ProjectTag,
  ServiceBlockListItem,
  Webhook,
} from 'shared/api/schedule/api-schedule.responses';
import {
  JobStepId,
  ServiceBlockId,
  StepExemptionRequestId,
  StepServiceStartId,
  UserAccountId,
  VehicleId,
  WebhookId,
} from 'shared/constants/id.constants';


const daySummaryMap = {
  hoveredJobStepId: null as null | JobStepId,
  selectedJobStepId: null as null | JobStepId,
  hoveredServiceBlockId: null as null | ServiceBlockId,
  selectedServiceBlockId: null as null | ServiceBlockId,
  hoveredProfessionalId: null as null | UserAccountId,
  selectedProfessionalId: null as null | UserAccountId,
  hoveredVehicleId: null as null | VehicleId,
  selectedVehicleId: null as null | VehicleId,
  dismissedProfessionalJobIds: new Array<JobStepId>(),
};

export const homeState = manageStateSlice({
  projectList: {
    scrollPos: 0,
    filters: {
      search: '',
    },
  },
  projectVehicleGrantList: {
    scrollPos: 0,
  },
  projectTagList: {
    listItems: new Array<ProjectTag>(),
    scrollPos: 0,
    filters: {
      archived: false,
    }
  },
  daySummaryMap,
  trackingPositions: new Array<TrackerPosition>(),
  dayScheduleSummaries: {} as DayScheduleSummaries,
  projectSummaries: new Array<ProjectSummary>(),
  activeProjectGrants: {} as ProjectGrantsSummary,
  stepExemptionRequestIdsForUnAcknowledgedExemptions: new Array<StepExemptionRequestId>(),
  stepServiceStartIdsForUnAcknowledgedMedia: new Array<StepServiceStartId>(),
  currentExemptionToReview: {} as ExemptionReview,
  currentMediaToReview: {} as MediaReview,
  projectNotes: { } as ListNotes,
  jobStepExemptionNotes: {} as ListNotes,
  jobStepMediaNotes: {} as ListNotes,
  webhooks: new Array<Webhook>(),
  activeDates: new Array<string>(),
  showSearchInputInHeader: false,
  searchString: '',
  addSchedule: false,
})({
  addSchedule: (state, payload: boolean) => {
    state.addSchedule = payload;
  },
  searchString: (state, payload: string) => {
    state.searchString = payload;
  },
  showSearchInputInHeader: (state, payload: boolean) => {
    state.showSearchInputInHeader = payload;
  },
  activeDates: (state, payload: string[]) => {
    state.activeDates = payload;
  },
  jobStepExemptionNotes: (state, payload: ListNotes) => {
    state.jobStepExemptionNotes = payload;
  },
  jobStepExemptionNote: (state, payload: CreateNote) => {
    state.jobStepExemptionNotes.notes.replaceElseInsert(payload.note, e => e.id);
    state.jobStepExemptionNotes.userPersonProfiles.replaceElseInsert(payload.userPersonProfile, e => e.id);
  },
  jobStepMediaNotes: (state, payload: ListNotes) => {
    state.jobStepMediaNotes = payload;
  },
  jobStepMediaNote: (state, payload: CreateNote) => {
    state.jobStepMediaNotes.notes.replaceElseInsert(payload.note, e => e.id);
    state.jobStepMediaNotes.userPersonProfiles.replaceElseInsert(payload.userPersonProfile, e => e.id);
  },
  projectNotes: (state, payload: ListNotes) => {
    state.projectNotes = payload;
  },
  projectNote: (state, payload: CreateNote) => {
    state.projectNotes.notes.replaceElseInsert(payload.note, e => e.id);
    state.projectNotes.userPersonProfiles.replaceElseInsert(payload.userPersonProfile, e => e.id);
  },
  trackerPositions: (state, payload: TrackerPosition[]) => {
    state.trackingPositions = payload;
  },
  projectSummaryAdd: (state, payload: ProjectSummary) => {
    state.projectSummaries.push(payload);
  },
  projectSummaries: (state, payload: ProjectSummary[]) => {
    state.projectSummaries = payload;
  },
  projectListFilters: (state, payload: { search: string }) => {
    state.projectList.filters = payload;
  },
  projectListScroll: (state, payload: number) => {
    state.projectList.scrollPos = payload;
  },
  projectVehicleGrantListScroll: (state, payload: number) => {
    state.projectVehicleGrantList.scrollPos = payload;
  },
  projectTagsListItems: (state, payload: ProjectTag[]) => {
    state.projectTagList.listItems = payload;
  },
  projectTagsListItemAppend: (state, payload: ProjectTag) => {
    state.projectTagList.listItems.push(payload);
  },
  projectTagsListItemUpdate: (state, payload: ProjectTag) => {
    state.projectTagList.listItems.replace(payload, e => e.id);
  },
  projectTagListFilters: (state, payload: { archived: boolean }) => {
    state.projectTagList.filters = payload;
  },
  projectTagListScroll: (state, payload: number) => {
    state.projectTagList.scrollPos = payload;
  },
  dayScheduleSummaries: (state, payload: DayScheduleSummaries) => {
    state.dayScheduleSummaries = payload;
  },
  stepExemptionRequestIdsForUnAcknowledgedExemptions: (state, payload: StepExemptionRequestId[]) => {
    state.stepExemptionRequestIdsForUnAcknowledgedExemptions = payload;
  },
  stepServiceStartIdsForUnAcknowledgedMedia: (state, payload: StepServiceStartId[]) => {
    state.stepServiceStartIdsForUnAcknowledgedMedia = payload;
  },
  activeProjectGrants: (state, payload: ProjectGrantsSummary) => {
    state.activeProjectGrants = payload;
  },
  activeProjectGrantRemove: (state, payload: UserAccountId) => {
    state.activeProjectGrants.projectGrants.remove(e => e.userAccountId === payload);
  },
  activeProjectGrantUpdate: (state, payload: ProjectGrant) => {
    state.activeProjectGrants.projectGrants.replace(payload, e => e.userAccountId);
  },
  currentExemptionToReview: (state, payload: ExemptionReview) => {
    state.currentExemptionToReview = payload;
  },
  currentMediaToReview: (state, payload: MediaReview) => {
    state.currentMediaToReview = payload;
  },
  acknowledgeExemption: (state) => {
    state.stepExemptionRequestIdsForUnAcknowledgedExemptions.remove(e => e === state.currentExemptionToReview.stepExemptionRequestId);
  },
  acknowledgeMedia: (state) => {
    state.stepServiceStartIdsForUnAcknowledgedMedia.remove(e => e === state.currentMediaToReview.stepServiceStartId);
  },
  webhooks: (state, payload: Webhook[]) => {
    state.webhooks = payload;
  },
  webhookRemove: (state, payload: WebhookId) => {
    state.webhooks.remove(e => e.id === payload);
  },
  webhookAdd: (state , payload: Webhook) => {
    state.webhooks.push(payload);
  },
  webhookUpdate: (state, payload: Webhook) => {
    state.webhooks.replace(payload, e => e.id);
  },
  scheduleServiceBlockUpdate: (state, payload: ServiceBlockListItem) => {
    const toUpdate = state.dayScheduleSummaries.schedules
      .flatMap(s => s.serviceBlocks)
      .findOrThrow(sp => sp.serviceBlockId === payload.serviceBlock.id);
    toUpdate.startDate = payload.serviceBlock.startDate;
    toUpdate.endDate = payload.serviceBlock.endDate;
    toUpdate.serviceBlockStatusId = payload.serviceBlock.statusId;
    toUpdate.placeName = payload.place.name;
    toUpdate.placeAddress = payload.place.streetAddress || '';
    toUpdate.placeLatitude = payload.place.latitude;
    toUpdate.placeLongitude = payload.place.longitude;
  },
  daySummaryMap: (state, payload: typeof daySummaryMap) => {
    state.daySummaryMap = payload;
  }
});
