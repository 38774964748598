import * as dayjs from 'dayjs';
import { manageStateSlice } from 'g2i-ngrx-utils';
import {
  ProfessionalBookingSummaryListItem,
  ScheduleListItem,
  SchedulesSummaryBounds,
  SchedulesSummaryListItem,
  SchedulesSummarySchedule,
  ScheduleSummary,
  VehicleBookingSummaryListItem,
} from 'shared/api/schedule/api-schedule.responses';
import { ProjectId, ScheduleStatusId } from 'shared/constants/id.constants';

import { TimeScale } from './project-planner.types';


export interface ProjectPlannerFilters {
  statusId: ScheduleStatusId | 0;
  withChangeRequests: boolean;
  startDate: string;
  endDate: string;
  includeItemsWithoutBookingRights: boolean;
}

export const projectPlannerState = manageStateSlice({
  bounds: {} as SchedulesSummaryBounds,
  timelineListItems: new Array<SchedulesSummaryListItem>(),
  resourcesListItems: new Array<VehicleBookingSummaryListItem>(),
  professionalsListItems: new Array<ProfessionalBookingSummaryListItem>(),
  popupDetails: {} as { [key: string]: SchedulesSummarySchedule },
  timescale: 'weeks' as TimeScale,
  alreadyFetchedWeeks: new Array<string>(),
  alreadyFetchedMonths: new Array<string>(),
  alreadyFetchedDays: new Array<string>(),
  scheduleSummaries: new Array<ScheduleListItem>(),
  fetchingDateRanges: new Array<{ startDate: string; endDate: string }>(),
  projectId: '',
  currentlyViewedTab: 'timeline',
  filterControls: new Array<keyof ProjectPlannerFilters>(),
  filters: {
    statusId: null as ScheduleStatusId | null,
    withChangeRequests: false,
    startDate: '',
    endDate: '',
    includeItemsWithoutBookingRights: false,
  } as ProjectPlannerFilters,
})({
  resetState: (state) => {
    state.timelineListItems = [];
    state.resourcesListItems = [];
    state.professionalsListItems = [];
    state.alreadyFetchedMonths = [];
    state.alreadyFetchedWeeks = [];
    state.alreadyFetchedDays = [];
  },
  bounds: (state, payload: SchedulesSummaryBounds) => {
    state.bounds.start = payload.start;
    state.bounds.end = dayjs.max(dayjs(), dayjs(payload.end)).toISOString();
  },
  projectId: (state, projectId: ProjectId) => {
    state.projectId = projectId;
  },
  currentlyViewedTab: (state, payload: string) => {
    state.currentlyViewedTab = payload;
  },
  alreadyFetchedMonths: (state, payload: { startDate: string; endDate: string }) => {
    state.alreadyFetchedMonths = [
      ...state.alreadyFetchedMonths,
      ...new Array(dayjs(payload.endDate).diff(payload.startDate, 'months') / 6)
        .fill(null)
        .map((e, i) => dayjs(payload.startDate).add(i * 6, 'months').format('YYYY-MM-DD')),
    ].distinct().sort();
    state.fetchingDateRanges.push(payload);
  },
  alreadyFetchedWeeks: (state, payload: { startDate: string; endDate: string }) => {
    state.alreadyFetchedWeeks = [
      ...state.alreadyFetchedWeeks,
      ...new Array(dayjs(payload.endDate).diff(payload.startDate, 'months'))
        .fill(null)
        .map((e, i) => dayjs(payload.startDate).add(i, 'months').format('YYYY-MM-DD')),
    ].distinct().sort();
    state.fetchingDateRanges.push(payload);
  },
  alreadyFetchedDays: (state, payload: { startDate: string; endDate: string }) => {
    state.alreadyFetchedDays = [
      ...state.alreadyFetchedDays,
      ...new Array(dayjs(payload.endDate).diff(payload.startDate, 'weeks'))
        .fill(null)
        .map((e, i) => dayjs(payload.startDate).add(i, 'weeks').format('YYYY-MM-DD')),
    ].distinct().sort();
    state.fetchingDateRanges.push(payload);
  },
  timelineListItems: (state, payload: { items: SchedulesSummaryListItem[]; startDate: string; endDate: string }) => {
    state.timelineListItems = [
      ...state.timelineListItems,
      ...payload.items.filter(x => !!x.dateRanges.length),
    ].distinct(e => e.scheduleId)
      .sort((a, b) => a.dateRanges[0].start.localeCompare(b.dateRanges[0].start));
    state.fetchingDateRanges.remove(dr => dr.startDate === payload.startDate && dr.endDate === payload.endDate);
  },
  resourcesListItems: (state, payload: { items: VehicleBookingSummaryListItem[]; startDate: string; endDate: string }) => {
    state.resourcesListItems = [
      ...state.resourcesListItems,
      ...payload.items.filter(x => !!x.dateRanges.length),
    ].distinct(e => e.scheduleId)
      .sort((a, b) => a.dateRanges[0].start.localeCompare(b.dateRanges[0].start));
    state.fetchingDateRanges.remove(dr => dr.startDate === payload.startDate && dr.endDate === payload.endDate);
  },
  professionalsListItems: (state, payload: { items: ProfessionalBookingSummaryListItem[]; startDate: string; endDate: string }) => {
    state.professionalsListItems = [
      ...state.professionalsListItems,
      ...payload.items.filter(x => !!x.dateRanges.length),
    ].distinct(e => e.scheduleId)
      .sort((a, b) => a.dateRanges[0].start.localeCompare(b.dateRanges[0].start));
    state.fetchingDateRanges.remove(dr => dr.startDate === payload.startDate && dr.endDate === payload.endDate);
  },
  popupDetails: (state, payload: { [key: string]: SchedulesSummarySchedule }) => {
    Object.assign(state.popupDetails, payload);
  },
  popupDetailsClear: (state) => {
    state.popupDetails = {};
  },
  timescale: (state, payload: TimeScale) => {
    state.timescale = payload;
    state.alreadyFetchedDays = [];
    state.alreadyFetchedWeeks = [];
    state.alreadyFetchedMonths = [];
    state.timelineListItems = [];
    state.resourcesListItems = [];
    state.professionalsListItems = [];
    state.fetchingDateRanges = [];
  },
  scheduleSummaries: (state, payload: ScheduleListItem[]) => {
    state.scheduleSummaries = payload;
  },
  scheduleSummariesAppend: (state, payload: ScheduleListItem[]) => {
    state.scheduleSummaries.push(...payload);
  },
  filters: (state, payload: ProjectPlannerFilters) => {
    state.filters = payload;
  },
  filterControls: (state, payload: Array<keyof ProjectPlannerFilters>) => {
    state.filterControls = payload;
  },
});
