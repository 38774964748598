import { Injectable } from '@angular/core';
import { ApiBaseService } from 'shared/api/api-base.service';
import { ProjectId, UserAccountId } from 'shared/constants/id.constants';

import { ProjectGrant, ProjectGrantsSummary } from '../api-schedule.responses';
import {
  ArchiveProjectGrantRequest,
  CreateProjectGrantsRequest,
  FindUserAccountIdByEmailRequest,
  UpdateProjectGrantRequest,
} from './api-project-grant.requests';


@Injectable({ providedIn: 'root' })
export class ApiProjectGrantService extends ApiBaseService {

  constructor() {
    super(() => `${this.serviceUrl.schedule}/api/schedule/${this.routeParam.organizationid}/project`);
  }
  listActive = (projectId: ProjectId) =>
    this.request<ProjectGrantsSummary>(`${projectId}/grant`, 'GET');
  create = (request: CreateProjectGrantsRequest) =>
    this.request<ProjectGrantsSummary>(`${request.projectId}/grant`, 'POST', request);
  update = (request: UpdateProjectGrantRequest) =>
    this.request<ProjectGrant>(`${request.projectId}/grant/userAccount/${request.userAccountId}`, 'PUT', request);
  archive = (request: ArchiveProjectGrantRequest) =>
    this.request<ProjectGrant>(`${request.projectId}/grant/userAccount/${request.userAccountId}`, 'DELETE');
  findUserAccountIdByEmail = (request: FindUserAccountIdByEmailRequest) =>
    this.request<UserAccountId>(`${request.projectId}/grant/userAccountId/email/${request.email}`, 'GET');
  me = () =>
    this.request<ProjectGrant[]>(`grant/me`, 'GET');

}
