import { Injectable } from '@angular/core';
import { ApiBaseService } from 'shared/api/api-base.service';
import { GooglePlaceId, PlaceId } from 'shared/constants/id.constants';

import { Place } from '../api-place.responses';
import {
  ArchivePlaceRequest,
  CreatePlaceFromGoogleRequest,
  CreatePlaceRequest,
  ListPlacesRequest,
} from './api-place.requests';

@Injectable({ providedIn: 'root' })
export class ApiPlaceService extends ApiBaseService {

  constructor() {
    super(() => `${this.serviceUrl.place}/api/place`);
  }

  list = (request: ListPlacesRequest) =>
    this.request<Place[]>(``, 'GET', request);
  archive = (request: ArchivePlaceRequest) =>
    this.request<Place>(``, 'PATCH', request);
  find = (id: PlaceId) =>
    this.request<Place>(`${id}`, 'GET');
  listPlaceTypes = () =>
    this.request<string[]>(`type`, 'GET');
  listTimezones = () =>
    this.request<string[]>(`timezone`, 'GET');
  create = (request: CreatePlaceRequest) =>
    this.request<Place>(``, 'POST', request);
  createFromGooglePlace = (request: CreatePlaceFromGoogleRequest) =>
    this.request<Place>(`from-google`, 'POST', request);
  listCustomPlaces = (searchString: string) =>
    this.request<Place[]>(`custom`, 'GET', { searchString });
  findByPlaceId = (placeId: GooglePlaceId) =>
    this.request<Place>(`place-id/${placeId}`, 'GET');
  listByPlaceIds = (placeIds: PlaceId[]) =>
    this.request<Place[]>(`for-ids-list`, 'POST', placeIds);
}
