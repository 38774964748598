import { manageStateSlice } from 'g2i-ngrx-utils';
import { Vehicle, VehicleGrant } from 'shared/api/asset/api-asset.response';
import { UserPersonProfile } from 'shared/api/organization/api-organization.response';
import { DepotSummary } from 'shared/api/place/api-place.responses';
import { ProfessionalProfile, ProfessionalProfileGrant, ProfessionalType } from 'shared/api/professional/api-professional.responses';
import {
  Project,
  ProjectGrant,
  ProjectProfessionalProfileGrant,
  ProjectProfessionalProfileGrantSummaries,
  ProjectVehicleGrant,
  ScheduleConfiguration,
  StepExemptionReason, Webhook,
} from 'shared/api/schedule/api-schedule.responses';
import { ProjectId } from 'shared/constants/id.constants';

export const sharedState = manageStateSlice({
  vehicles: new Array<Vehicle>(),
  projectVehicleGrants: new Array<ProjectVehicleGrant>(),
  vehicleGrants: new Array<VehicleGrant>(),
  projects: new Array<Project>(),
  professionalTypes: new Array<ProfessionalType>(),
  depots: new Array<DepotSummary>(),
  stepExemptionReasons: new Array<StepExemptionReason>(),
  professionalProfiles: new Array<ProfessionalProfile>(),
  professionalProfileGrants: new Array<ProfessionalProfileGrant>(),
  userPersonProfiles: new Array<UserPersonProfile>(),
  projectProfessionalProfileGrants: new Array<ProjectProfessionalProfileGrant>(),
  timezones: new Array<string>(),
  scheduleConfig: {} as ScheduleConfiguration,
  myProjectGrants: new Array<ProjectGrant>(),
})({
  myProjectGrants: (state, payload: ProjectGrant[]) => {
    state.myProjectGrants = payload;
  },
  myProjectGrant: (state, payload: ProjectGrant) => {
    state.myProjectGrants.replaceElseInsert(payload, g => g.projectId === payload.projectId);
  },
  myProjectGrantRemove: (state, payload: ProjectId) => {
    state.myProjectGrants.remove(e => e.projectId === payload);
  },
  timezones: (state, payload: string[]) => {
    state.timezones = payload;
  },
  scheduleConfig: (state, payload: ScheduleConfiguration) => {
    state.scheduleConfig = payload;
  },
  stepExemptionReasons: (state, payload: StepExemptionReason[]) => {
    state.stepExemptionReasons = payload;
  },
  depots: (state, payload: DepotSummary[]) => {
    state.depots = payload;
  },
  depotAdd: (state, payload: DepotSummary) => {
    state.depots.push(payload);
  },
  depot: (state, payload: DepotSummary) => {
    state.depots.replace(payload, e => e.depot.id);
  },
  vehicles: (state, payload: Vehicle[]) => {
    state.vehicles = payload;
  },
  projectVehicleGrants: (state, payload: ProjectVehicleGrant[]) => {
    state.projectVehicleGrants = payload;
  },
  projectVehicleGrantAdd: (state, payload: ProjectVehicleGrant) => {
    state.projectVehicleGrants.push(payload);
  },
  projectVehicleGrant: (state, payload: ProjectVehicleGrant) => {
    state.projectVehicleGrants.replace(payload, e => e.id);
  },
  vehicleGrants: (state, payload: VehicleGrant[]) => {
    state.vehicleGrants = payload;
  },
  projects: (state, payload: Project[]) => {
    state.projects = payload;
  },
  projectAdd: (state, payload: Project) => {
    state.projects.push(payload);
  },
  projectUpdate: (state, payload: Project) => {
    state.projects.replace(payload, e => e.id);
  },
  professionalTypes: (state, payload: ProfessionalType[]) => {
    state.professionalTypes = payload;
  },
  projectVehicleGrantUpdate: (state, payload: ProjectVehicleGrant) => {
    state.projectVehicleGrants.replace(payload, e => e.id);
  },
  projectProfessionalProfileGrantSummaries: (state, payload: ProjectProfessionalProfileGrantSummaries) => {
    Object.assign(state, payload);
  },
  projectProfessionalProfileGrants: (state, payload: ProjectProfessionalProfileGrant[]) => {
    state.projectProfessionalProfileGrants.merge(payload, e => e.id);
  },
  projectProfessionalProfileGrantsUpdate: (state, payload: ProjectProfessionalProfileGrant) => {
    state.projectProfessionalProfileGrants.replace(payload, e => e.id);
  },
});
