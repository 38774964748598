import { manageStateSlice } from 'g2i-ngrx-utils';
import { TrackerPosition } from 'shared/api/asset/api-asset.response';
import { CreateNote, ListNotes } from 'shared/api/note/api-note.responses';
import { Route, VehicleBooking } from 'shared/api/schedule/api-schedule.responses';
import {
  RouteRequest,
  SaveVehicleBookingRequest,
  WayPointRequest,
} from 'shared/api/schedule/vehicle-booking/api-vehicle-booking.requests';
import { ProjectVehicleGrantId, ServiceBlockId, VehicleBookingId } from 'shared/constants/id.constants';
import { isDefined } from 'shared/utils/strict-null-check.utils';

export const scheduleVehiclesState = manageStateSlice({
  bookings: new Array<VehicleBooking>(),
  notes: {} as ListNotes,
  userAcknowledgedConflictingGrantWithOwnVehicleBooking: false,
  trackingPositions: new Array<TrackerPosition>(),
  vehicleDialog: {
    bookingToSave: null as any as SaveVehicleBookingRequest,
    serviceBlockIdsThatOverlapWithOtherBookings: new Array<ServiceBlockId>(),
    editingStepIndex: 0,
    editingStep3Index: 0,
    step1Dirty: false,
    step2Dirty: false,
    step3Dirty: false,
    step1Error: '',
    step2Error: '',
    step3Error: '',
  },
})({
  editingStep3Index: (state, payload: number) => {
    state.vehicleDialog.editingStep3Index = payload;
  },
  notes: (state, payload: ListNotes) => {
    state.notes = payload;
  },
  note: (state, payload: CreateNote) => {
    state.notes.notes.replaceElseInsert(payload.note, e => e.id);
    state.notes.userPersonProfiles.replaceElseInsert(payload.userPersonProfile, e => e.id);
  },
  bookings: (state, payload: VehicleBooking[]) => {
    state.bookings = payload;
  },
  bookingToSaveProjectVehicleGrantId: (state, payload: ProjectVehicleGrantId) => {
    isDefined(state.vehicleDialog.bookingToSave).projectVehicleGrantId = payload;
  },
  bookingAdd: (state, payload: VehicleBooking) => {
    state.bookings.push(payload);
  },
  bookingUpdate: (state, payload: VehicleBooking) => {
    state.bookings.replace(payload, b => b.id === payload.id);
  },
  trackerPositions: (state, payload: TrackerPosition[]) => {
    state.trackingPositions = payload;
  },
  bookingToSave: (state, payload?: SaveVehicleBookingRequest) => {
    state.vehicleDialog.bookingToSave = payload || null as any;
  },
  bookingToSaveWayPoint: (state, payload: WayPointRequest) => {
    state.vehicleDialog.bookingToSave.wayPoints.replace(payload, wp => wp.id);
  },
  bookingToSaveRoute: (state, payload: RouteRequest) => {
    state.vehicleDialog.bookingToSave.routes.replace(payload, r => r.id === payload.id);
  },
  editingStepIndex: (state, payload: number) => {
    state.vehicleDialog.editingStepIndex = payload;
  },
  enabledVehicleBookingSlotServiceBlockIds: (state, payload: ServiceBlockId[]) => {
    state.vehicleDialog.bookingToSave.enabledVehicleBookingSlotServiceBlockIds = payload;
  },
  serviceBlockIdsThatOverlapWithOtherBookings: (state, payload: ServiceBlockId[]) => {
    state.vehicleDialog.serviceBlockIdsThatOverlapWithOtherBookings = payload;
  },
  bookingStep1Error: (state, payload: string) => {
    state.vehicleDialog.step1Error = payload;
  },
  bookingStep1Dirty: (state, payload: boolean) => {
    state.vehicleDialog.step1Dirty = payload;
  },
  bookingStep2Error: (state, payload: string) => {
    state.vehicleDialog.step2Error = payload;
  },
  bookingStep2Dirty: (state, payload: boolean) => {
    state.vehicleDialog.step2Dirty = payload;
  },
  bookingStep3Error: (state, payload: string) => {
    state.vehicleDialog.step3Error = payload;
  },
  bookingStep3Dirty: (state, payload: boolean) => {
    state.vehicleDialog.step3Dirty = payload;
  },
  resetBookingDialog: (state) => {
    state.vehicleDialog = scheduleVehiclesState.initialState.vehicleDialog;
  },
  vehicleRoutes: (state, payload: { vehicleBookingId: VehicleBookingId; routes: Route[] }) => {
    state.bookings.findOrThrow(b => b.id === payload.vehicleBookingId).routes = payload.routes;
  },
});
