import { InjectionToken } from '@angular/core';
import { ActionReducerMap } from '@ngrx/store';
import { combineManagers } from 'g2i-ngrx-utils';
import { shellState } from 'shared/store/shell.state';

import { depotsState } from '../../app/main/depots/depots.state';
import { homeState } from '../../app/main/home/home.state';
import { projectPlannerState } from '../../app/main/home/project-detail/project-planner/project-planner.state';
import { scheduleVehiclesState } from '../../app/main/schedules/schedule-detail/schedule-vehicles/schedule-vehicles.manager';
import { scheduleProfessionalsState } from '../../app/main/schedules/schedule-detail/schedule-professionals/schedule-professionals.manager';
import { schedulesState } from '../../app/main/schedules/schedules.state';
import { serviceBlocksState } from '../../app/main/service-blocks/service-blocks.state';
import { vehicleState } from '../../app/main/vehicles/vehicles.state';
import { AppState } from './interfaces';
import { sharedState } from './shared.state';

export const managers = combineManagers({
  shell: shellState,
  home: homeState,
  projectPlanner: projectPlannerState,
  depot: depotsState,
  shared: sharedState,
  serviceBlock: serviceBlocksState,
  schedule: schedulesState,
  scheduleVehicle: scheduleVehiclesState,
  scheduleProfessional: scheduleProfessionalsState,
  vehicle: vehicleState,
});

export const reducerToken = new InjectionToken<ActionReducerMap<AppState>>('ImplReducers');

export const getReducers = () => managers.reducers;
