import { Injectable } from '@angular/core';
import { ApiBaseService } from 'shared/api/api-base.service';

import { ProjectProfessionalProfileGrant, ProjectProfessionalProfileGrantSummaries } from '../api-schedule.responses';
import {
  ArchiveProjectProfessionalProfileGrantRequest,
  SaveProjectProfessionalProfileGrantRequest,
} from './professional-profile.requests';

@Injectable({ providedIn: 'root' })
export class ApiProjectProfessionalProfileGrantService extends ApiBaseService {

  constructor() {
    super(() => `${this.serviceUrl.schedule}/api/schedule/${this.routeParam.organizationid}/project-professional-profile-grant`);
  }

  list = () =>
    this.request<ProjectProfessionalProfileGrantSummaries>(``, 'GET');
  save = (request: SaveProjectProfessionalProfileGrantRequest) =>
    this.request<ProjectProfessionalProfileGrant[]>('', 'POST', request);
  archive = ({projectProfessionalProfileGrantId, ...request}: ArchiveProjectProfessionalProfileGrantRequest) =>
    this.request<ProjectProfessionalProfileGrant>(projectProfessionalProfileGrantId, 'DELETE', request);

}
