import { manageStateSlice } from 'g2i-ngrx-utils';
import { ServiceEventsAndUsers } from 'shared/api/logging/api-logging.responses';
import { CreateNote, ListNotes } from 'shared/api/note/api-note.responses';
import { ServiceBlockListItem, ServiceBlockProfessionalActivity, ServiceBlockSummary } from 'shared/api/schedule/api-schedule.responses';
import { ListServiceBlocksRequest } from 'shared/api/schedule/serviceblock/api-serviceblock.requests';
import { serviceBlockStatuses } from 'shared/constants/lookup.constants';


export const serviceBlocksState = manageStateSlice({
  serviceBlockList: {
    scrollPos: 0,
    listItems: new Array<ServiceBlockListItem>(),
    filters: {
      placeName: '' as string,
      startDate: null as null | string,
      endDate: null as null | string,
      statusIds: [serviceBlockStatuses.inProgress.id, serviceBlockStatuses.schedule.id, serviceBlockStatuses.toAction.id],
      hasPendingChangeRequests: null as null | boolean,
    } as Omit<ListServiceBlocksRequest, 'offset'>,
  },
  serviceBlockSummary: {} as ServiceBlockSummary,
  professionalActivity: new Array<ServiceBlockProfessionalActivity>(),
  serviceBlockNotes: {} as ListNotes,
  activityList: {
    filters: {
      startDate: null as string | null,
      endDate: null as string | null,
    },
    listItems: {} as ServiceEventsAndUsers,
  }
})({
  serviceBlockNotes: (state, payload: ListNotes) => {
    state.serviceBlockNotes = payload;
  },
  serviceBlockNote: (state, payload: CreateNote) => {
    state.serviceBlockNotes.notes.replaceElseInsert(payload.note, e => e.id);
    state.serviceBlockNotes.userPersonProfiles.replaceElseInsert(payload.userPersonProfile, e => e.id);
  },
  activityList: (state, payload: ServiceEventsAndUsers) => {
    state.activityList.listItems = payload;
  },
  activityListAppend: (state, payload: ServiceEventsAndUsers) => {
    state.activityList.listItems.serviceEvents.push(...payload.serviceEvents);
    state.activityList.listItems.userPersonProfiles.push(...payload.userPersonProfiles);
  },
  activityListFilters: (state, payload: { startDate: string | null; endDate: string | null }) => {
    state.activityList.filters = payload;
  },
  serviceBlockListFilters: (state, payload: Omit<ListServiceBlocksRequest, 'offset'>) => {
    state.serviceBlockList.filters = payload;
  },
  serviceBlockListScroll: (state, payload: number) => {
    state.serviceBlockList.scrollPos = payload;
  },
  serviceBlockSummary: (state, payload: ServiceBlockSummary) => {
    state.serviceBlockSummary = payload;
  },
  professionalActivity: (state, payload: ServiceBlockProfessionalActivity[]) => {
    state.professionalActivity = payload;
  },
  serviceBlockListItems: (state, payload: ServiceBlockListItem[]) => {
    state.serviceBlockList.listItems = payload;
  },
  serviceBlockListItemsAppend: (state, payload: ServiceBlockListItem[]) => {
    state.serviceBlockList.listItems.push(...payload);
  },
  serviceBlockListItemsPrepend: (state, payload: ServiceBlockListItem[]) => {
    state.serviceBlockList.listItems.unshift(...payload);
  },
  serviceBlockListItemUpdate: (state, payload: ServiceBlockListItem) => {
    state.serviceBlockList.listItems.replace(payload, e => e.serviceBlock.id);
  },
});
