import { Injectable } from '@angular/core';
import { ApiBaseService } from 'shared/api/api-base.service';

import { ProfessionalType } from './api-professional.responses';

@Injectable({ providedIn: 'root' })
export class ApiProfessionalService extends ApiBaseService {

  constructor() {
    super(() => `${this.serviceUrl.professional}/api/professional/${this.routeParam.organizationid}`);
  }

  listProfessionalTypes = () =>
    this.request<ProfessionalType[]>('professional-type', 'GET');

}
