import { Injectable } from '@angular/core';
import { ApiBaseService } from 'shared/api/api-base.service';
import { TrackerPosition, Vehicle, VehicleGrant } from 'shared/api/asset/api-asset.response';
import { VehicleId } from 'shared/constants/id.constants';

@Injectable({ providedIn: 'root' })
export class ApiScheduleVehicleService extends ApiBaseService {

  constructor() {
    super(() => `${this.serviceUrl.schedule}/api/schedule/${this.routeParam.organizationid}/vehicle`);
  }

  listGrantsForGrantee = () =>
    this.request<VehicleGrant[]>(`grant/grantee`, 'GET');
  listVehiclesForGrantee = () =>
    this.request<Vehicle[]>(`grantee`, 'GET');
  listTrackerPositionsForGrantee = (vehicleIds: VehicleId[]) =>
    this.request<TrackerPosition[]>(`position/grantee`, 'POST', vehicleIds);
  findTrackerPositionForGranteeVehicle = (vehicleId: VehicleId) =>
    this.request<TrackerPosition>(`position/grantee/vehicle/${vehicleId}`, 'GET');

}
