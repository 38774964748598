import { Injectable } from '@angular/core';
import { ApiBaseService } from 'shared/api/api-base.service';

import { ProjectVehicleGrant } from '../api-schedule.responses';
import {
  ArchiveProjectVehicleGrantRequest,
  CreateProjectVehicleGrantRequest,
  UpdateProjectVehicleGrantRequest,
} from './api-project-vehicle-grant.requests';


@Injectable({ providedIn: 'root' })
export class ApiProjectVehicleGrantService extends ApiBaseService {

  constructor() {
    super(() => `${this.serviceUrl.schedule}/api/schedule/${this.routeParam.organizationid}/project-vehicle-grant`);
  }

  list = () =>
    this.request<ProjectVehicleGrant[]>('', 'GET');
  create = (request: CreateProjectVehicleGrantRequest) =>
    this.request<ProjectVehicleGrant>('', 'POST', request);
  update = ({ projectVehicleGrantId, ...request }: UpdateProjectVehicleGrantRequest) =>
    this.request<ProjectVehicleGrant>(projectVehicleGrantId, 'PUT', request);
  setHasBookingRights = ({ projectVehicleGrantId, ...request }: ArchiveProjectVehicleGrantRequest) =>
    this.request<ProjectVehicleGrant>(`${projectVehicleGrantId}/has-booking-rights`, 'PUT', request);

}
