import { Injectable } from '@angular/core';
import { ApiBaseService } from 'shared/api/api-base.service';

import { ScheduleConfiguration, StepExemptionReason } from '../api-schedule.responses';

@Injectable({ providedIn: 'root' })
export class ApiConfigurationService extends ApiBaseService {

  constructor() {
    super(() => `${this.serviceUrl.schedule}/api/schedule/configuration`);
  }
  configuration = () =>
    this.request<ScheduleConfiguration>('', 'GET');
  stepExemptionReasons = () =>
    this.request<StepExemptionReason[]>('step-exemption-reason', 'GET');

}
