import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { G2iAuthService } from 'g2i-ng-auth';
import { G2iMediaSupportServiceOnline } from 'g2i-ng-media-control';
import { combineLatest } from 'rxjs';
import { concatMap, tap } from 'rxjs/operators';
import { ApiDepotService } from 'shared/api/place/depot/api-depot.service';
import { ApiPlaceService } from 'shared/api/place/place/api-place.service';
import { ApiProfessionalService } from 'shared/api/professional/api-professional.service';
import { ApiConfigurationService } from 'shared/api/schedule/configuration/api-configuration.service';
import {
  ApiProjectProfessionalProfileGrantService,
} from 'shared/api/schedule/professional-profile/professional-profile.service';
import { ApiProjectGrantService } from 'shared/api/schedule/project-grant/api-project-grant.service';
import { ApiProjectVehicleGrantService } from 'shared/api/schedule/project-vehicle-grant/api-project-vehicle-grant.service';
import { ApiProjectService } from 'shared/api/schedule/project/api-project.service';
import { ApiScheduleVehicleService } from 'shared/api/schedule/schedule-vehicle/api-schedule-vehicle.service';
import { pipe } from 'shared/utils/rxjs.utils';

import { sharedState } from '../../shared/store/shared.state';

@Injectable({ providedIn: 'root' })
export class MainResolver implements Resolve<any> {

  constructor(
    readonly apiScheduleVehicle: ApiScheduleVehicleService,
    readonly apiProject: ApiProjectService,
    readonly authService: G2iAuthService,
    readonly apiProfessional: ApiProfessionalService,
    readonly apiPlace: ApiPlaceService,
    readonly apiDepot: ApiDepotService,
    readonly apiScheduleConfig: ApiConfigurationService,
    readonly apiProjectProfessionalProfileGrant: ApiProjectProfessionalProfileGrantService,
    readonly apiProjectVehicleGrant: ApiProjectVehicleGrantService,
    readonly apiProjectGrant: ApiProjectGrantService,
  ) {
    /** @deprecated */
    G2iMediaSupportServiceOnline.init({ token: this.authService.getAuthHeader().Authorization.substring('Bearer '.length) });
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return combineLatest([
      pipe(
        concatMap(() => this.apiProjectGrant.me()),
        tap(response => sharedState.dispatch.myProjectGrants(__filename, response)),
      ),
      pipe(
        concatMap(() => this.apiPlace.listTimezones()),
        tap(response => sharedState.dispatch.timezones(__filename, response)),
      ),
      pipe(
        concatMap(() => this.apiScheduleConfig.configuration()),
        tap(response => sharedState.dispatch.scheduleConfig(__filename, response)),
      ),
      pipe(
        concatMap(() => this.apiScheduleConfig.stepExemptionReasons()),
        tap(response => sharedState.dispatch.stepExemptionReasons(__filename, response)),
      ),
      pipe(
        concatMap(() => this.apiScheduleVehicle.listVehiclesForGrantee()),
        tap(response => sharedState.dispatch.vehicles(__filename, response)),
      ),
      pipe(
        concatMap(() => this.apiScheduleVehicle.listGrantsForGrantee()),
        tap(response => sharedState.dispatch.vehicleGrants(__filename, response)),
      ),
      pipe(
        concatMap(() => this.apiProjectVehicleGrant.list()),
        tap(response => sharedState.dispatch.projectVehicleGrants(__filename, response)),
      ),
      pipe(
        concatMap(() => this.apiProject.list()),
        tap(response => sharedState.dispatch.projects(__filename, response)),
      ),
      pipe(
        concatMap(() => this.apiProfessional.listProfessionalTypes()),
        tap(response => sharedState.dispatch.professionalTypes(__filename, response)),
      ),
      pipe(
        concatMap(() => this.apiDepot.list()),
        tap(response => sharedState.dispatch.depots(__filename, response)),
      ),
      pipe(
        concatMap(() => this.apiProjectProfessionalProfileGrant.list()),
        tap(response => sharedState.dispatch.projectProfessionalProfileGrantSummaries(__filename, response)),
      ),
    ]);
  }

}
