import { manageStateSlice } from 'g2i-ngrx-utils';

export const depotsState = manageStateSlice({
  depotsList: {
    filters: {
      search: '',
    }
  },
})({
  depotListFilters: (state, payload: { search: string }) => {
    state.depotsList.filters = payload;
  },
});
