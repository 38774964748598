import { manageStateSlice } from 'g2i-ngrx-utils';
import { ServiceBlockFilters } from 'projects/app-implementation-partner/src/shared/store/interfaces';
import { CreateNote, ListNotes, Note } from 'shared/api/note/api-note.responses';
import { UserPersonProfileLight } from 'shared/api/organization/api-organization.response';
import { Schedule, ScheduleSummary, ServiceBlockListItem } from 'shared/api/schedule/api-schedule.responses';
import { ListSchedulesRequest } from 'shared/api/schedule/schedule/api-schedule.requests';
import { JobId, ScheduleStatusId } from 'shared/constants/id.constants';

export const schedulesState = manageStateSlice({
  scheduleList: {
    scrollPos: 0,
    listItems: new Array<ScheduleSummary>(),
    filters: {
      offset: 0,
      searchString: '',
      startDate: null as null | string,
      endDate: null as null | string,
      statusIds: new Array<ScheduleStatusId>(),
      hasPendingChangeRequests: null as null | boolean,
    } as ListSchedulesRequest,
  },
  serviceBlocksAvailableForProject: new Array<ServiceBlockListItem>(),
  scheduleUsersForServiceBlocks: new Array<UserPersonProfileLight>(),
  serviceBlockFilters: {
    placeName: '',
    startDate: null as null | string,
    endDate: null as null | string,
  } as ServiceBlockFilters,
  scheduleServiceBlocks: new Array<ServiceBlockListItem>(),
  schedule: null as null | Schedule,
  jobsWithConflictingSlots: new Array<JobId>(),
  scheduleNotes: {} as ListNotes,
  jobNotes: {} as ListNotes,
  jobStepNotes: {} as ListNotes,
})({
  scheduleNotes: (state, payload: ListNotes) => {
    state.scheduleNotes = payload;
  },
  scheduleNote: (state, payload: CreateNote) => {
    state.scheduleNotes.notes.replaceElseInsert(payload.note, e => e.id);
    state.scheduleNotes.userPersonProfiles.replaceElseInsert(payload.userPersonProfile, e => e.id);
  },
  jobNotes: (state, payload: ListNotes) => {
    state.jobNotes = payload;
  },
  jobNote: (state, payload: CreateNote) => {
    state.jobNotes.notes.replaceElseInsert(payload.note, e => e.id);
    state.jobNotes.userPersonProfiles.replaceElseInsert(payload.userPersonProfile, e => e.id);
  },
  jobStepNotes: (state, payload: ListNotes) => {
    state.jobStepNotes = payload;
  },
  jobStepNote: (state, payload: CreateNote) => {
    state.jobStepNotes.notes.replaceElseInsert(payload.note, e => e.id);
    state.jobStepNotes.userPersonProfiles.replaceElseInsert(payload.userPersonProfile, e => e.id);
  },
  jobsWithConflictingSlots: (state, payload: JobId[]) => {
    state.jobsWithConflictingSlots = payload;
  },
  schedulesListFilters: (state, payload: ListSchedulesRequest) => {
    Object.assign(state.scheduleList.filters, payload);
  },
  scheduleListScroll: (state, payload: number) => {
    state.scheduleList.scrollPos = payload;
  },
  scheduleServiceBlocks: (state, payload: ServiceBlockListItem[]) => {
    state.scheduleServiceBlocks = payload;
  },
  scheduleServiceBlockUpdate: (state, payload: ServiceBlockListItem) => {
    state.scheduleServiceBlocks.replace(payload, e => e.serviceBlock.id);
  },
  scheduleListItems: (state, payload: ScheduleSummary[]) => {
    state.scheduleList.listItems = payload;
  },
  scheduleListItemsAppend: (state, payload: ScheduleSummary[]) => {
    state.scheduleList.listItems.push(...payload);
  },
  schedule: (state, payload?: Schedule) => {
    state.schedule = payload || null;
  },
  serviceBlocksAvailableForProjectAdd: (state, payload: ServiceBlockListItem[]) => {
    state.serviceBlocksAvailableForProject.push(...payload);
  },
  serviceBlockAvailableForProjectUpdate: (state, payload: ServiceBlockListItem) => {
    state.serviceBlocksAvailableForProject.replace(payload, e => e.serviceBlock.id);
  },
  serviceBlocksAvailableForProject: (state, payload: ServiceBlockListItem[]) => {
    state.serviceBlocksAvailableForProject = payload;
  },
  scheduleUsersForServiceBlocks: (state, payload: UserPersonProfileLight[]) => {
    state.scheduleUsersForServiceBlocks = payload;
  },
  serviceBlockFilters: (state, payload: ServiceBlockFilters) => {
    state.serviceBlockFilters = payload;
  },
});
