import { OrganizationAppGuard } from 'g2i-ng-auth';
import { AppRoute, shellRoutes } from 'shared/constants/route.consts';
import { ShellAppGuard } from 'shared/services/shell-app.guard';

import { MainResolver } from './main/main.resolver';

export const routes: AppRoute[] = [
  ...shellRoutes,
  {
    path: 'app',
    canActivate: [ShellAppGuard, OrganizationAppGuard],
    runGuardsAndResolvers: 'always', // Required for OrganizationAppGuard to function smoothly
    children: [
      {
        path: ':organizationid',
        loadChildren: () => import('./main/main.routes').then(m => m.routes),
        resolve: { data: MainResolver },
      },
    ],
  },
  {
    path: '**',
    redirectTo: 'landing',
  },
];
