import { Injectable } from '@angular/core';
import { ApiBaseService } from 'shared/api/api-base.service';

import { DepotSummary } from '../api-place.responses';
import { ArchiveDepotRequest, CreateDepotRequest, UpdateDepotRequest } from './api-depot.requests';

@Injectable({ providedIn: 'root' })
export class ApiDepotService extends ApiBaseService {

  constructor() {
    super(() => `${this.serviceUrl.place}/api/place/${this.routeParam.organizationid}/depot`);
  }

  list = () =>
    this.request<DepotSummary[]>('', 'GET');
  create = (request: CreateDepotRequest) =>
    this.request<DepotSummary>('', 'POST', request);
  update = (request: UpdateDepotRequest) =>
    this.request<DepotSummary>('', 'PUT', request);
  archive = (request: ArchiveDepotRequest) =>
    this.request<DepotSummary>('', 'PATCH', request);
}
