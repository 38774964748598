import { manageStateSlice } from 'g2i-ngrx-utils';
import {TrackerPosition, Vehicle, VehicleGrant} from 'shared/api/asset/api-asset.response';
import { ServiceBlockListItem } from 'shared/api/schedule/api-schedule.responses';

export const vehicleState = manageStateSlice({
  vehicles: new Array<Vehicle>(),
  vehicleGrants: new Array<VehicleGrant>(),
  selectedVehicle: null as null | Vehicle,
  serviceBlocks: {
    items: new Array<ServiceBlockListItem>(),
  }
})({
  vehicleAdd: (state, payload: Vehicle) => {
    state.selectedVehicle = payload;
  },
  serviceBlocks: (state, payload: ServiceBlockListItem[]) => {
    state.serviceBlocks.items = payload;
  },
  vehicleGrantee: (state, payload: Vehicle[]) => {
    state.vehicles = payload;
  },
  vehicleGrants: (state, payload: VehicleGrant[]) => {
    state.vehicleGrants = payload;
  },
});
