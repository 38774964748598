import { Injectable } from '@angular/core';
import { ApiBaseService } from 'shared/api/api-base.service';
import { ProjectId } from 'shared/constants/id.constants';

import { Project, ProjectSummary, ProjectTag } from '../api-schedule.responses';
import {
  ArchiveProjectRequest,
  CreateProjectRequest,
  ArchiveProjectTagRequest,
  CreateProjectTagRequest,
  UpdateProjectTagRequest,
  UpdateProjectRequest,
} from './api-project.requests';


@Injectable({ providedIn: 'root' })
export class ApiProjectService extends ApiBaseService {

  constructor() {
    super(() => `${this.serviceUrl.schedule}/api/schedule/${this.routeParam.organizationid}/project`);
  }
  list = () =>
    this.request<Project[]>('', 'GET');
  create = (request: CreateProjectRequest) =>
    this.request<Project>('', 'POST', request);
  update = (request: UpdateProjectRequest) =>
    this.request<Project>(request.projectId, 'PUT', request);
  archive = ({ projectId, ...request }: ArchiveProjectRequest) =>
    this.request<Project>(projectId, 'DELETE', request);
  listTags = (projectId: ProjectId) =>
    this.request<ProjectTag[]>(`${projectId}/tag`, 'GET');
  createTag = (request: CreateProjectTagRequest) =>
    this.request<ProjectTag>(`${this.routeParam.projectId}/tag`, 'POST', request);
  updateTag = ({ projectTagId, ...request }: UpdateProjectTagRequest) =>
    this.request<ProjectTag>(`${this.routeParam.projectId}/tag/${projectTagId}`, 'PUT', request);
  archiveTag = ({ projectTagId, ...request }: ArchiveProjectTagRequest) =>
    this.request<ProjectTag>(`${this.routeParam.projectId}/tag/${projectTagId}`, 'DELETE', request);
  listServiceBlockCounts = () =>
    this.request<ProjectSummary[]>('service-block-counts', 'GET');

}
